.home-main {
    overflow: hidden;
}

// Video Section
.home-video {
    width: 100%;
    height: calc(100vh - 14rem);
    position: relative;
    overflow: hidden;
    @include mq("small") {
        height: calc(100vh - 10rem);
    }
    video.siegel-video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
        transform: translateX(-50%) translateY(-50%);
        transition:top 1.4s, opacity 1s;
        @include mq("small") {
            width: auto;
            height: 100%;
        }
    }
    &__content {
        width: 80%;
        margin-left: 5%;
        position: absolute;
        bottom: 6vh;
        transition:bottom 1s, opacity 1s;
        @include mq("small") {
            width: 90%;
        }
        h1 {
            margin-bottom: 3rem;
            font-size: 3.6rem;
            @include mq("small") {
                font-size: 2.2rem;
            }
        }
        .btn-play {
            font-size: 1.4rem;
            letter-spacing: 1px;
            @include mq("small") {
                font-size: 1.2rem;
            }
        }
    }
}

// Services Section
.home-services {
    margin-top: $v-margin;
    margin-bottom: $v-margin * 2;
    position: relative;
    &__imgbg {
        position: absolute;
        width: 100vw;
        height: 100%;
        z-index: -1;
        background:url(../img/service_bg.jpg) no-repeat right center;
        top: 0;
        right: -9%;
        &:after,
        &:before {
            content: '';
            position: absolute;
        }
    }
}
.home-services-nav {
    margin-left: 10%;
    @include mq("small") {
        margin-left: 0;
    }
    &__list {
        font-size: 3.2rem;
        font-family: $serif;
        line-height: 2;
        margin-bottom: 1rem;
        @include mq("small") {
            font-size: 2.4rem;
        }
        &:after {
            content: '';
            display: block;
            width: 40px;
            height: 1px;
            background-color: $key-color;
            transform: scaleX(0);
            transform-origin: left center;
            transition: transform 0.5s;
        }
        &:hover {
            &:after {
                transform: scaleX(1);
            }
        }
        span {
            font-size: 2rem;
            @include mq("small") {
                font-size: 1.6rem;
            }
        }
    }
}

// Services Wheel

.home-services-wheel {
    width: 35%;
    position: absolute;
    top: 10%;
    right: 0;
    @include mq("medium") {
        width: 45%;
    }
    @include mq("small") {
        width: 60%;
        top: 40%;
        right: -18%;
    }
    @include mq("x-small") {
        width: 80%;
        top: 40%;
        right: -18%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 1s;
        cursor: pointer;
        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
}

// Service Bg Typo
.home-services-bg {
    position: absolute;
    width: 100%;
    right: -50%;
    top: 50%;
    @include mq("small") {
        top: 100%;
        right: -30%;
    }
    li {
        font-size: 20rem;
        font-family: $serif;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        overflow: hidden;
        transition: all 1s;
        &.active {
            opacity: 0.2;
            visibility: visible;
        }
        @include mq("medium") {
            font-size: 16rem;
        }
        @include mq("small") {
            font-size: 10rem;
        }
    }
}

.home-artists {
    margin-top: $v-margin * 2;
    @include mq("small") {
        margin-top: $v-margin-sp * 2;
    }
}

.img__mask{
    position: relative;
    @include mq("small") {
        height: 200px;
        overflow: hidden;
        img{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}


body.loading {
    overflow: hidden;
}
.loadaing-container {
    transition:all 1s;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    background: #000;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    .loadaing-logo {
        opacity: 0;
        width: 320px;
        // transition: 5.25s ease-in-out 1s;
        @include mq("small") {
            width: 200px;
        }
    }
}
.loading {
    .loadaing-container {
        opacity: 1;
        visibility: visible;
        .loadaing-logo {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .home-video {
        video.siegel-video{
            opacity: 0;
            top: 68vh;
        }
        .home-video__content{
            opacity: 0;
            bottom: -3vh;
        }
    }
}