.filter {
  border-bottom: 1px solid #fff;
  padding-bottom: 12rem;

  .flex-box {
    justify-content: flex-start;
  }

  .search-text {
    letter-spacing: 3px;
    text-transform: uppercase;
    color: $key-color;

    &::placeholder {
      color: $key-color;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $key-color;
    }

    &::-ms-input-placeholder {
      color: $key-color;
    }
  }

  h1 {
    font-family: $sans-serif;
    text-transform: uppercase;
    font-size: 3.2rem;
    font-weight: 100;
    letter-spacing: 5px;
    margin: 6rem 0;
  }

  .section-title {
    margin-bottom: 2.2rem;
  }

  h3 {
    color: $key-color;
    font-family: $sans-serif;
    text-transform: uppercase;
    font-size: 1.3rem;
    letter-spacing: 3px;
    margin-bottom: 2rem;
  }


  .location-filters {
    width: 20%;

    button {
      display: block;
      letter-spacing: 3px;
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
  }

  .preferences-filters,
  .genres-filters {
    width: 30%;

    button {
      display: block;
      text-transform: none;
      letter-spacing: 2px;
      font-size: 1.3rem;
      margin-bottom: 1.4rem;
      text-align: left;
    }
  }

  .live-music-filters {
    width: 45%;
    margin-right: 20%;

    &__left,
    &__right {
      width: 48%;
    }
  }

  .dj-filters {
    width: 30%;
  }

  .btn-container {
    text-align: right;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .search-btn {
      margin-left: 20%;
      width: 80%;
      border: 1px solid $key-color;
      padding: 0.5rem 0;
      color: $key-color;
    }
  }
}