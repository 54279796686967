.single-main {
    padding-top: 0;
}
.single {
    &__left {
        width: 60%;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        @include mq("medium") {
            width: 50%;
        }
        @include mq("small") {
            position: absolute;
            width: 100%;
            height:300px;
            top: 130px;
            .bg-image {
                // background-position: center top;
            }
        }
    }

    &__right {
        position: relative;
        width: 30%;
        // height: 100vh;
        margin-left: 65%;
        margin-bottom: 6vh;
        padding-top: 6vh;
        top: 10vh;
        @include mq("medium") {
            width: 40%;
            margin-left: 55%;
            height: auto;
        }
        @include mq("small") {
            width: 88%;
            height: auto;
            margin:0 6%;
            margin-top:460px;
            top: 0;
        }
    }
    &__title {
        line-height: 1;
        font-size: 5rem;
        @include mq("small") {
            font-size: 4rem;
        }
    }
    .line {
        width: 50%;
        height: 1px;
        background-color: $line-color;
        margin: 3rem 0 3.6rem;
        @include mq("small") {
            margin: 2rem 0;
        }
    }
    &__intro {
        font-size: 2.2rem;
        margin: 2rem 0 4rem;
        @include mq("small") {
            margin: 2rem 0;
            font-size: 1.8rem;
        }
    }
    &__text {
        p {
            margin-bottom: 1em;
        }
    }
    .btn-back {
        padding-top: 3em;
        padding-bottom: 5em;
        a {
            display: inline;
        }
    }
}
