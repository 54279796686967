.clientele {
    &__logos {
        display: flex;
        flex-wrap: wrap;
        height: 200px;
        width: 100%;
        justify-content: center;

        @media screen and (max-width: 991px) {
            height: 400px;
        }

        &__logo {
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            flex: 0 0 200px;
            margin-bottom: 25px;
            flex-direction: row;

            
            @media screen and (max-width: 991px) {
                flex: 0 0 40%;
            }

            &:nth-child(1) {
                background-image: url("../img/logo-westin.png");
            }
            &:nth-child(2) {
                background-image: url("../img/logo-fairmont.png");
            }
            &:nth-child(3) {
                background-image: url("../img/logo-shangri.png");
            }
            &:nth-child(4) {
                background-image: url("../img/logo-emc.png");
            }
            &:nth-child(5) {
                background-image: url("../img/logo-yvr.png");
            }
            &:nth-child(6) {
                background-image: url("../img/logo-notch.png");
            }
            &:nth-child(7) {
                background-image: url("../img/logo-rosewood.png");
            }
            &:nth-child(8) {
                background-image: url("../img/logo-royal.png");
            }
        }
    }
}