.footer.inner-wrap {
    margin-top: $v-margin;
    margin-bottom: 4rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $line-color;

    .footer__left {
        margin-bottom: 4rem;
        @include mq("small") {
            width: 100%;
            margin-bottom: 2rem;
        }
        p {
            margin-bottom: 1rem;
        }
        a {
            margin-bottom: 0.6rem;
        }
    }
    .footer__center {
        @include mq("small") {
            width: 100%;
            margin-bottom:4rem;
            margin-top: 3rem;
            justify-content: flex-start;
        }
        li {
            margin-bottom: 0.6rem;
        }
    }
    .footer__right {
        width: 40%;
        @include mq("medium") {
            width: 100%;
        }
        p {
            margin-bottom: 1rem;
        }
        input {
            padding-bottom: 0.6rem;
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }
        button {
            font-size: 1.2rem;
            margin-top: 2rem;
        }
    }
    body.single-profile & {
        display: none;
    }
}

.footer-nav {
    margin-right: 6rem;
	@include mq("small") {
        margin-right: 4rem;
	}
}

.copyright {
    display: block;
    margin-top: $v-margin-sp;
}