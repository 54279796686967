.mailchimp-form {
    &[data-state="success"] {
        .results__success {
            display: block;
        }
        .results__error {
            display: none;
        }
    }
    &[data-state="error"] {
        .results__error {
            display: block;
        }
        .results__success {
            display: none;
        }
    }

    .results__success, .results__error{
        display: none;
    }

    .results__error {
        color: red;
    }
    input[type="submit"]{
        letter-spacing: 1px;
        border-bottom: 1px solid $key-color;
    }
}