// -----------------------
// Projects Showcase
// -----------------------

.project-showcase {
    width: 100%;
    position: relative;
    margin-bottom: $v-margin * 1.7;
    @include mq("medium") {
		flex-direction: column-reverse;
        margin-bottom: $v-margin-sp;
    }
    &__title {
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        margin-top: 3rem;
        top: 0;
        z-index: 5;
        @include mq("medium") {
            order: 1;
            position: relative;
            top: 0;
            margin-top: 0;
            margin-bottom: 1em;
        }
        h2 {
            line-height: 1;
        }
        a, span {
            color: $key-color;
            text-transform: uppercase;
            font-size: 1.2rem;
            letter-spacing: 1.4px;
            @include mq("small") {
                font-size: 1rem;
            }
        }
    }

    &__left {
        width: 30%;
        margin-top: 30rem;
        text-align: justify;
        @include mq("medium") {
            position: relative;
            width: 100%;
            margin-top: 1em;
            margin-bottom: 1em;
        }
        @include mq("small") {
            text-align: left;
        }

        a {
            display: inline-block;
            margin-top: 3rem;
            padding-bottom: 0.5rem;
            text-transform: uppercase;
            font-size: 1.2rem;
            letter-spacing: 1px;
        }
    }
    &__right {
        width: 60%;
        overflow: hidden;
        position: relative;

        @include mq("medium") {
            width: 100%;
            position: relative;
            margin-top: -3.9em;
        }
        img {
            filter: grayscale(1);
            position: relative;
            z-index: -1;
            transition: transform 0.6s, filter 0.6s;
        }

        .project-showcase__bgblack {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            position: absolute;
            top: 0;
            left: 0;
            opacity: 1;
            visibility: visible;
            transition: all 0.6s;
        }
    }
    &.hover {
        .project-showcase__right {
            img {
                filter: grayscale(0);
            }
            .project-showcase__bgblack{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

.project-showcase--reverse {
    .project-showcase__left {
        order: 2;
        @include mq("medium") {
            order: initial;
        }
    }
}