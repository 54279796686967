.artists-main {
    .category-wrapper {
        margin-bottom: 18rem;
        position: relative;
        @include mq("small") {
            margin-bottom: $v-margin-sp;
        }
    }

    .grid__title {
        margin-bottom: 10rem;
        @include mq("small") {
            margin-bottom: $v-margin-sp;
        }
    }
    .grid__item {
        width: 23% !important;
        @include mq("small") {
            width: 48% !important;
        }
        .img__mask {
            @include mq("small") {
                height: 300px;
            }
        }

        &--last {
            position: relative;
            .center {
                width: 90%;
            }
            img {
                filter: grayscale(1);
            }
            p {
                font-size: 1.6rem;
                margin-bottom: 2rem;
                text-transform: none;
                @include mq("small") {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .two-column {
        margin-bottom: 9rem;
        @include mq("small") {
            margin-bottom: 3rem;
        }

        .accordion-box {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s;
            margin-top: 2rem;
            p {
                margin-bottom: 2rem;
            }
        }

        &__intro {
            width: 90%;
            h3 {
                margin-top: 2rem;
                margin-bottom: 1rem;
            }
        }

        button.accordion-open {
            font-size: 1.2rem;
            outline: none;
            letter-spacing: 1px;
        }
    }
    .two-column__video {
        position: relative;
        padding-bottom: 53.5%;
        padding-top: 40px;
        height: 0;
        margin-bottom: $v-margin;
        z-index: 2;
        overflow: hidden;
        transform: rotate(0.001deg); // For Chrome bug
        
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .artists-arrow {
        position: absolute;
        bottom: 350px;
        right: -20px;
        width: 33px;
        height: 40px;
        cursor: pointer;
        transform: rotate(90deg);
        outline: none;
        &.swiper-button-disabled {
            display: none;
        }
        @include mq("medium") {
            bottom: 250px;
        }
        @include mq("small") {
            height: 10px;
            right: -30px;
        }
    }
    .artists-nav-prev {
        left: -40px;
        transform: rotate(-90deg);
        @include mq("small") {
            left: -35px;
        }
    }
}
