.insta-feed {
    margin-bottom: $v-margin;
    @include mq("small") {
        margin-bottom: $v-margin-sp;
    }
    &__img {
        width: 20%;
        margin-right: 5%;
        margin-bottom: 5%;
        height: 260px;
        position: relative;
        filter: grayscale(1);
        transition: filter 0.6s;

        @include mq("medium") {
            width: 30%;
        }
        @include mq("small") {
            width: 47.5%;
            height: 200px;
            top: 0;
            padding-top: 3rem;
        }

        &:hover {
            filter: grayscale(0);

            .text {
                opacity: 1;
                visibility: visible;
            }
            .meta {
                opacity: 1;
                visibility: visible;
            }
        }

        &:nth-child(4n) {
            margin-right: 0;
            @include mq("medium") {
                margin-right: 5%;
            }
        }
        &:nth-child(3n) {
            @include mq("medium") {
                margin-right: 0;
            }
        }
        &:nth-child(2n){
            margin-top: 5%;
            @include mq("small") {
                margin-right: 0;
                margin-top: 0;
            }
        }

        .text {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 5;
            transform: translate(-50%, -50%);
            width: 90%;
            opacity: 0;
            visibility: hidden;
            transition: all 0.6s;
            @include mq("small") {
                font-size: 1.2rem;
            }
        }

        .meta {
            position: absolute;
            bottom: 3px;
            left: 50%;
            z-index: 5;
            transform: translateX(-50%);
            width: 90%;
            opacity: 0;
            visibility: hidden;
            transition: all 0.6s;
            &__left {
                width: 90%;
                span {
                    margin-right: 5px;
                }
                i {
                    padding-right: 5px;
                }
            }
            &__right {
                width: 10%;
                text-align: right;
            }
        }
    }
}
