// Video Popup
.video-popup {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $bg-color;
    z-index: 500;
    opacity: 0;
    visibility: hidden;

    // Cover
    &__cover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 700;
        height: 50vh;
        width: 100%;
        background-color: $bg-color;
        &--bottom {
            top: auto;
            bottom: 0;
        }
    }
    .video-container {
        max-width: 1400px;
        width: 80%;
    }
    &__box {
        position: relative;
        padding-bottom: 53.5%;
        padding-top: 40px;
        height: 0;
        iframe {
            border: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        } 
    }
    .btn-close {
        position: fixed;
        top: 3rem;
        right: 3rem;
        width: 25px;
        height: 25px;
        z-index: 600;
        border-bottom: 0;
        @include mq("small") {
            width: 20px;
            height: 20px;
        }
    }
    .plyr__control, .plyr__controls {
        opacity: 0;
        pointer-events: none;
    }
    .plyr__video-wrapper{
        > div {
            transform: none !important;
        }
    }
}