.siegel-video {
    max-width: 600px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 2vh;
}

.text-mouseover {
    margin-bottom: 10vh;
    opacity: 0;
    transition: all .5s ease;

    &.active {
        opacity: 1;
        transition: all .5s ease;
    }
}

.video-container {
    z-index: 10;
    width: 480px;

    @media screen and (max-width: 480px) {
        width: 100%;
    }

    &__video {
        width: 100%;
    }

    &__x {
        position: absolute;
        right: 0;
        top: 0;
        background: black;
        padding: 5px;
        line-height: 14px;
        cursor: pointer;
    }
}