.contact {
    margin-top: $v-margin;
    font-size: 1.5rem;
    line-height: 1.8;
    @include mq("small") {
        margin-top: $v-margin-sp;
    }
    .contact-four-column {
        justify-content: flex-start;
    }
    h1 {
        margin-bottom: $v-margin-sp;
    }
    h2.contact-sub-title {
        margin-bottom: 1.8rem;
        text-transform: uppercase;
        font-family: $sans-serif;
        font-size: 1.5rem;
        letter-spacing: 1px;
        font-weight: bold;
        line-height: 1.4;
    }

    &__info {
        margin-top: $v-margin / 2;
        width: 25%;
        @include mq("medium") {
            width: 50%;
        }
        @include mq("small") {
            width: 100%;
            margin-top: $v-margin-sp / 2;
        }
    }
    &__viewmap {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 1.2rem;
        letter-spacing: 1px;
        display: inline-block;
    }

}

// Contact form
.contact__form {
    margin-top: $v-margin;
    @include mq("small") {
        margin-top: $v-margin-sp;
    }
    form {
        margin-top: 2rem;
    }
    .input-list {
        width: 32%;
        margin-bottom: 2.6rem;
        @include mq("medium") {
            width: 48%;
        }
        @include mq("small") {
            width: 100%;
        }
        &--wide {
            width: 100%;
            textarea {
                height: 200px;
            }
        }
    }
}

.contact-main .instagram__link {
    margin-top: $v-margin;
    .wide-link--contact__info {
        left: 50%;
        top: 55%;
        transform: translateX(-45%);
        @include mq("medium") {
            transform: translateX(-49%);
        }
        @include mq("small") {
            position: static;
            transform: translateX(0);
            margin-left: 2rem;
        }
        a {
            font-size: 2.6rem;
            display: inline-block;
            margin-right: 4rem;
            transition: color 0.4s;
            @include mq("small") {
                margin-top: 1rem;
                font-size: 2rem;
            }

            &:hover {
                color: rgba(255, 255, 255, 0.4) !important;
            }
        }
    }

    &:hover {
        h2 {
            transform: translateY(-60%);
            @include mq("small") {
                transform: translateY(0);
            }
        }
    }
}

.submission-contact {
    margin-top: $v-margin;
}

// Submission
.submission__header {
    margin-top: $v-margin / 2;
    @include mq("small") {
        margin-top: $v-margin-sp / 2;
    }
    ul {
        padding-left: 2rem;
        li {
            list-style-type: disc;
        }
    }
}

.submission {
    .contact__form {
        margin-top: $v-margin / 2;
        @include mq("small") {
            margin-top: $v-margin-sp / 2;
        }
    }
}



// Gravity form CSS
.contact__form {
    .gform_wrapper .top_label .gfield_label {
        display: none;
    }
    .gform_wrapper ul.gform_fields {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 1.2rem;
    }
    .gform_wrapper ul.gform_fields li.gfield {
        width: 32%;
        margin-bottom: 2.6rem;
        padding: 0;
        margin-top: 0;
        @include mq("medium") {
            width: 48%;
        }
        @include mq("small") {
            width: 100%;
        }
        &:last-child {
            width: 100%;
        }
    }
    .gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium,
    .gform_wrapper .top_label input.medium.datepicker {
        width: 100%;
    }
    .gform_wrapper .gfield_time_hour {
        label, i {
            display: none;
        }
    }
    .gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label {
        display: none;
    }
    .gform_wrapper .gfield_time_hour,
    .gform_wrapper .gfield_time_minute,
    .gform_wrapper .gfield_time_ampm {
        width: 32%;
        max-width: none;
        input {
            width: 100%;
        }
    }
    .gform_wrapper .gfield_time_ampm {
        border-bottom: 1px solid #fff;
        margin-top: 9px;
    }
    .gform_wrapper.gf_browser_chrome .gfield_time_ampm select {
        width: 100%;
        max-width: none !important;
        padding: 5px 4px;
        border-radius: 0 !important;
        border: none;
    }
    .gform_wrapper textarea {
        width: 100%;
        padding: 5px 4px;
    }
    .gform_wrapper .gform_footer {
        padding: 0;
        margin: 0;
    }
    .gform_wrapper .gform_footer input.button {
        border-bottom: 1px solid $key-color;
        padding-bottom: 0.5rem;
        letter-spacing: 1px;
    }
    .gform_wrapper li.gfield.gfield_error {
        background-color: transparent;
        border: none;
    }
    .clear-multi {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
    .gform_wrapper .gform_footer input[type=submit] {
        width: auto;
    }
}
