.single-artist {
  padding-top: 16rem;
  padding-bottom: 14rem;
  position: relative;

  @include mq("small") {
    padding-top: 8rem;
    padding-bottom: 7rem;
  }

  &__right {
    width: 60%;
    position: relative;

    @include mq("small") {
      width: 100%;
      order: 1;
    }
  }

  &__left {
    width: 40%;

    @include mq("small") {
      width: 100%;
      order: 2;
      margin-top: $v-margin-sp;
    }
  }

  &__title {
    font-size: 6rem;
    line-height: 1;
    margin-bottom: 0.5rem;

    @include mq("small") {
      font-size: 4rem;
    }
  }

  &__meta {
    font-size: 1.2rem;
    margin-bottom: 3rem;
  }

  &__contact {
    display: inline-block;
    // text-transform: uppercase;
    margin-bottom: 3rem;

    i {
      margin-right: 1rem;
    }
  }

  &__img {
    width: 65%;
    margin-left: 30%;

    @include mq("medium") {
      width: 75%;
      margin-left: 25%;
    }
  }

  &__intro {
    font-size: 2.4rem;
    margin-top: 2rem;
    font-family: $serif;
    display: block;
  }

  &__content {
    font-size: 1.6rem;
    line-height: 1.8;
    letter-spacing: 0.2px;
    text-align: justify;

    @include mq("small") {
      text-align: left;
    }

    p {
      margin-bottom: 1.6rem;
    }
  }

  &__links {
    width: 95%;
    text-align: right;

    @include mq("medium") {
      width: 100%;
    }
  }

  &__link {
    font-size: 1.4rem;
    letter-spacing: 1.2px;
    margin-top: 1rem;
    margin-left: 2rem;
    display: inline-block;

    @include mq("small") {
      font-size: 1.2rem;
    }

    i {
      margin-right: 0.6rem;
    }
  }

  .btn-back {
    position: absolute;
    top: 6rem;
    right: 2.5%;
  }

  .btn-artist-detail {
    margin-bottom: 1rem;
  }
}

.single-artist-container {
  position: relative;
}

.single-artist__wrapper {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: all 0.3s ease-out;
  display: none;

  &.is-display {
    display: flex;
  }

  &.is-show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}

.calendar {
  width: 60%;

  #demoPicker {
    margin-top: 4rem;
  }
}

.booking-container {
  width: 30%;
  margin-top: 10rem;

  .booking-wrapper {
    margin-bottom: 4rem;

    h3 {
      color: $key-color;
      font-family: $sans-serif;
      text-transform: uppercase;
      font-size: 1.6rem;
      letter-spacing: 2px;
      margin-bottom: 1rem;
    }

    input {
      margin-bottom: 1rem;
    }
  }

  input[type="submit"] {
    letter-spacing: 3px;
    border-bottom: 1px solid $key-color;
  }
}