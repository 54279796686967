.single-post-content {
    padding-top: 28rem;
    padding-bottom: 14rem;
    @include mq("small") {
        padding-top: 14rem;
        padding-bottom: 7rem;
    }
    .inner-wrap--post {
        max-width: 800px;
        width: 85%;
        margin: 0 auto;
        @include mq("small") {
            width: 90%;
        }
    }

    .single-post__title {
        font-size: 5rem;
        line-height: 1;
        margin-bottom: 2rem;
        @include mq("small") {
            font-size: 3.2rem;
        }
    }
    .single-post__date {
        margin-bottom: 4rem;
    }
    .single-post__content {
        margin-top: 4rem;
        margin-bottom: 4rem;
        line-height: 1.8;
        p {
            margin-bottom: 2rem;
            @include mq("medium") {
                font-size: 1.4rem;
            }
        }
    }

}

.single-post-gallery {
    .single-post__image {
        margin: 0;
        margin-bottom: 4rem;
    }
}

.single-post-link {
    .wide-link--contact__info {
        position: static;
        transform: translateY(0);
    }
}