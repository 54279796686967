.artists {

    justify-content: space-evenly !important;

    &__title {
        margin-bottom: 30px !important;
        @media screen and (max-width: 991px) {
            margin-bottom: 15px !important;
        }
    }

    &__subtitle {
        margin-bottom: 20px !important;
        @media screen and (max-width: 991px) {
            margin-bottom: 15px;
        }
    }


    &__slider {

        width: 100vw;
        max-width: 1920px;


        .slick-slide {
            // margin: 0 30px;
            outline: none;

            &:focus, a {
                outline: none;
            }

            &.slick-center {

                .artists__slider__bg {
                    transform: scale(1.1);
                    transition: transform 0.3s ease;
                }
            }

            &:not(.slick-center) {
                .artists__slider__name {
                    font-weight: 900;
                    font-size: 16px;
                    line-height: 31px;
                    text-align: center;
                    letter-spacing: 12px;
                    text-transform: uppercase;
                    color: #4A4A4A;

                    @media screen and (max-width: 1450px) {
                        letter-spacing: 8px;
                    }

                    @media screen and (max-width: 991px) {
                        letter-spacing: 5px;
                    }
                }
            }
        }

        // .slick-list {
        //     margin: 0 -60px;
        // }

        &__bg {
            height: 100%;
            background-size: cover;
            background-position: center center;
            margin: 59px;
            height: 55vh;
            filter: grayscale(1);

            @media screen and (max-width: 991px) {
                margin: 15px;
                margin-bottom: 50px;
            }
        }

        &__name {
            font-weight: 300;
            font-size: 28px;
            line-height: 31px;
            text-align: center;
            letter-spacing: 20px;
            text-transform: uppercase;
            // white-space: nowrap;\
            height: 63px;

            @media screen and (max-width: 1450px) {
                letter-spacing: 10px;
            }

            @media screen and (max-width: 991px) {
                font-size: 18px;
                line-height: 18px;
                letter-spacing: 5px;
                height: 36px;
            }
        }
    }

    .slidecontainer {
        width: 100%; /* Width of the outside container */
        margin-top: 40px;
        @media screen and (max-width: 991px) {
            margin-top: 10px;
        }
      }
      
      /* The slider itself */
      .slider {
        -webkit-appearance: none;  /* Override default CSS styles */
        appearance: none;
        width: 100%; /* Full-width */
        height: 2px; /* Specified height */
        background: #313131; /* Grey background */
        outline: none; /* Remove outline */
        opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
        -webkit-transition: .2s; /* 0.2 seconds transition on hover */
        transition: opacity .2s;
      }
      
      /* Mouse-over effects */
      .slider:hover {
        opacity: 1; /* Fully shown on mouse-over */
      }
      
      /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
      .slider::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        border: 2px solid white; /* Green background */
        background: black;
        cursor: pointer; /* Cursor on hover */
        border-radius: 25px;
      }
      
      .slider::-moz-range-thumb {
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        border: 2px solid white; /* Green background */
        background: black; /* Green background */
        cursor: pointer; /* Cursor on hover */
        border-radius: 25px;
      }

}

.artist-gallery {

    height: auto !important;

    &__filters {

        display: flex;
        width: 100%;
        font-size: 10px;
        line-height: 26px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: white;
        justify-content: space-between;
        margin-bottom: 30px;

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }

        &__text {
            margin-right: 30px;
            flex: 1 0 auto;

            @media screen and (max-width: 991px) {
                display: none;
            }
        }

        &__left {
            display: flex;
            justify-content: center;
        }
        &__right {
            cursor: pointer;
        }

        &__divider {
            margin: 0 30px;
        }

        select {
    
            font-size: 10px;
            line-height: 26px;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: white;
            border-bottom: none;
            padding: 0;
            flex: 0 1 auto;

            option {
                background: black;
            }
    
        }
    }


    &__link {
        margin-bottom: 30px;
        flex: 0 0 20%;
        padding: 0 15px;

        @media screen and (max-width: 1400px) {
            flex: 0 0 25%;
        }

        @media screen and (max-width: 1200px) {
            flex: 0 0 33.33333%;
        }

        @media screen and (max-width: 767px) {
            flex: 0 0 50%;
        }

        @media screen and (max-width: 575px) {
            flex: 0 0 100%;
        }

        @media screen and (min-width: 1921px) {
            flex: 0 0 16.6666%;
        }

    }

    #searchFilter {
        margin-bottom: 30px;
        border-bottom-color: #E2A44A;

        &::placeholder {
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */
            letter-spacing: 1px;
            color: rgba(255, 255, 255, 0.3);
        }
    }

    &__bot {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        // width: -webkit-fill-available;
        margin: 0 -15px;
        max-height: calc(3 * 418px);
        overflow: hidden;
        transition: max-height 1s ease;

        a {
            padding: 0 15px;
        }

    }

    &__more {
        cursor: pointer;
    }

    &__container {

        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        
        &__bg {
            flex: 0 0 250px;
            background-size: cover;
            background-position: center 15%;
        }

        &__info {
            flex: 0 0 130px;
            background: #373737;
            font-size: 14px;
            line-height: 22px;
            padding: 20px;
            text-align: left;
            &__name {
                color: white;
                letter-spacing: 3px;
                text-transform: uppercase;
                margin-bottom: 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 44px;
                max-height: 44px;
            }
            &__categories {
                margin-bottom: 5px;
            }
            &__categories, &__location {
                color: rgba(255, 255, 255, 0.5);
                letter-spacing: 1px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }


}