.testimonials {

    // &__title {
        // margin-top: -200px;
    // }

    &__slider {

        width: 100vw;

        .slick-center {
            // -webkit-transform: scale(1.25);
            // -moz-transform: scale(1.25);
            // transform: scale(1.25);
            // .testimonials__slider__slide {
            //     font-size: 28px;
            // }
        }

        .slick-dots {
            list-style: none;

            li button:before {
                content: url(../img/dots.png);
                opacity: 1;
            }

            .slick-active button:before {
                content: '•';
                color: white;
            }
        }

        .slick-slide {
            outline: none;
            &:focus, a {
              outline: none;
            }
            
            &:not(.slick-center) {
                opacity: 0.5;
            }
        }

        &__slide {
            font-weight: 300;
            font-size: 21px;
            line-height: 31px;
            text-align: center;
            letter-spacing: 1px;
            transition: font-size 0.5s ease;

            @media screen and (max-width: 991px) {
                padding: 0 5px;
            }

            &__sig {
                font-size: 10px;
                line-height: 26px;
                letter-spacing: 3px;
                margin-top: 20px;
                text-transform: uppercase;

                &__pos {
                    color: #E2A44A;
                }
            }
            &__text {
                max-width: 490px;
                margin: auto;
            }
        }
    }
}
