html {
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
	font-size: 62.5%; //10px
}
*, *:before, *:after {
	box-sizing: inherit;
}


html,
body {
  width:100%;
  height:100%;
}

body {
	background: $bg-color;
	font-family: $sans-serif;
	font-size: 1.4rem;
	line-height:1.6;
	letter-spacing: 0.4px;
	color: $body-color;
}

main {
	@include mq("small") {
		padding-top: 10rem;
	}
	// overflow: hidden;
	padding-top: 14rem;
}

ul, li {
	margin: 0;
	padding: 0;
	list-style: none;
}

dl,dt,dd {
	margin: 0;
	padding: 0;
}

a{
	text-decoration: none;
	display: block;
	color: $body-color;
}

p{
	margin: 0;
	padding: 0;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

svg {
	pointer-events: none;
}

button{
	border: none;
	background-color: transparent;
	padding: 0;
	text-transform: uppercase;
	// border-bottom: 1px solid $body-color;
	&.submit{
		font-size: 1.2rem;
		border-bottom: transparent;
		letter-spacing: 2px;
	}
}


input[type="submit"] {
	border: none;
    border-bottom: transparent;
    border-radius: 0;
	background-color: transparent;
	padding: 1.2rem 0;
	text-transform: uppercase;
	font-size: 1.2rem;
}

input[type="email"],
input[type="phone"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="text"], textarea, select {
	border: none;
	border-bottom: 1px solid $color-grey-300;
	background-color: transparent;
	color: $body-color;
   	color:$key-color;
	width: 100%;
	font-size: 1.2rem;
    padding: 1.5rem 0;
    border-radius: 0;
	&:focus {
		outline: none;
	}
	&::placeholder {
		color: $body-color;
	}
	&:-ms-input-placeholder {
		color: $body-color;
	}
	&::-ms-input-placeholder {
		color: $body-color;
	}
}

textarea{
	height:100px;
	border:solid 1px white;
	padding:1rem;
	margin:1.5rem 0 0 0;

	&::placeholder {
		color: $color-grey-600;
	}
}


/* ---------------------------------
	Heading
-----------------------------------*/
h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
	line-height:1.2;
	margin: 0;
	padding: 0;
	font-family: $serif;
}
h1 {
	font-size: 6rem;
	@include mq("small") {
		font-size: 3.0rem;
		margin-bottom: 1em;
	}
}

h2 {
	font-size: 10rem;
	font-weight: 500;
	@include mq("medium") {
		font-size: 8.0rem;
	}
	@include mq("small") {
		font-size: 3.2rem;
	}
}

h3 {
	font-size: 2.0rem;
	@include mq("small") {
		font-size: 1.6rem;
	}
}


// Global Styles
// -----------------------------------

.bg-image{
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:0;
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
}

.flex-box {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&--sp-single{
		@include mq("small") {
			flex-direction: column;
		}
	}
}

.portrait-grid{
	justify-content: flex-start;
	margin: 0 -1%;
	.grid__item {
		margin-left: 1%;
		margin-right: 1%;
	}
}

.inner-wrap {
	position: relative;
	max-width: 1400px;
	width: 85%;
	margin: 0 auto;
	@include mq("small") {
		width: 90%;
	}
}
.inner-wrap--narrow {
	position: relative;
	max-width: 1000px;
	width: 85%;
	margin: 0 auto;
	@include mq("small") {
		width: 90%;
	}
}

.center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	@include mq("medium") {
		// position: static;
		// transform: translate(0, 0);
	}
	&--main-nav{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}
}

.v-center {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}


.wide-link {
    position: relative;
	padding: 13rem 0;
	text-align: center;
	background-color: $second-color;
	color: $body-color;
	@include mq("small") {
		padding: 6rem 0;
	}
	h2, a{
		font-size: 5rem;
		font-family: $serif;
		@include mq("small") {
			font-size: 2.4rem;
		}
	}
	&--contact {
		h2 {
			position: relative;
			transform: translateX(0);
			transition:all 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
		}
		&__info {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 45vw;
			transition:all 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
			@include mq("large"){
				left: 50vw;
			}
			@include mq("medium") {
				left: initial;
				text-align: center;
				width: 100%;
			}
			@include mq("small") {
				position: static;
				transform: translateY(0);
			}
			p {
				margin-bottom:0.8em;
				&:last-child {
					margin-bottom:0;
				}
			}
			a {
				color: $body-color;
				&:after {
					@include mq("small") {
						display: none;
					}
				}
			}
		}
		&__inner {
			text-align: left;
			@include mq("medium") {
				text-align: center;
				transform: translateY(40px);
			}
			@include mq("small") {
				transform: translateY(0);
			}
		}
		&:hover {
			h2 {
				transform: translateX(-15vw);
				@include mq("medium") {
					transform: translateX(0);
					transform: translateY(-20px);
				}
				@include mq("small") {
					opacity: 1;
					transform: translateY(0);
				}
			}
			.wide-link--contact__info {
				@extend .show;
				@include mq("medium") {
					left:initial;
				}
			}
		}
	}
}
.hidden-wide-link {
	opacity: 0;
	// visibility: hidden;
	@include mq("small") {
		opacity: 1;
	}
}
.show {
	opacity: 1;
	// visibility: visible;
}

.line {
	width: 100px;
	height: 1px;
	background-color: $line-color;
	margin: 0 0 3rem;
}

.list {
	margin: 1em 0;
	li {
		margin-bottom: 0.6rem;
		margin-left: 2rem;
		list-style-type: disc;
	}
}

.section-title {
    font-size: 1.6rem;
    margin-bottom: $v-margin / 2;
    font-family: $sans-serif;
    text-transform: uppercase;
	letter-spacing: 2px;
	@include mq("small") {
		font-size: 1.2rem;
	}
    &:before {
        content: '';
        display: block;
        width: 40px;
        height: 1px;
        background-color: $line-color;
        margin-bottom: 2rem;
    }
}

// hover ===================
[data-hover-line]{
	position: relative;
	backface-visibility: hidden;
	display: inline;
	&:after {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 1px;
		bottom: -3px;
		left: 0;
		background-color: $key-color;
		transform-origin: left center;
		transition: transform 0.5s;
	}
}
// on to off
[data-hover-line*="on"]{
	&:after {
		transform: scaleX(1);
	}
	&:hover {
		&:after {
			transform-origin: right 0;
			transform: scaleX(0);
		}
	}
}
[data-hover-line="on-white"]{
	&:after {
		background-color: $body-color;
	}
}

// off to on
[data-hover-line*="off"]{
	&:after {
		transform: scaleX(0);
	}
	&:hover {
		&:after {
			transform: scaleX(1);
		}
	}
}
[data-hover-line="off-black"]{
	&:after {
		background-color: $bg-color;
	}
}
[data-hover-line="off-white"]{
	&:after {
		background-color: $body-color;
	}
}

// Regional Contact
.regional-contact {
    position: relative;
    margin-bottom: $v-margin;
    @include mq("small") {
        margin-bottom: $v-margin-sp;
    }
    &__box {
        position: relative;
        width: 50%;
        height: 40vh;
        filter: grayscale(1);
        transition: filter 0.5s;

        &:hover {
            filter: grayscale(0);

            .regional-contact__info {
                .regional-contact__locations {
                    transform: translateY(0);
                    opacity: 1;
                }
                h3 {
                    transform: translateY(20px);
                    @include mq("small") {
                        transform: translateY(0px);
                    }
                }
                a {
                    transform: translateY(20px);
                    @include mq("small") {
                        transform: translateY(0px);
                    }
                }
            }
            .regional-contact__info.western-canada {
                h3 {
                    transform: translateY(40px);
                    @include mq("small") {
                        transform: translateY(0px);
                    }
                }
            }
            .regional-contact__info.international {
                h3 {
                    transform: translateY(0);
                }
            }
        }

        @include mq("small") {
            width: 100%;
            height: 240px;
        }
        .regional-contact__info {
            position: absolute;
            left: 5%;
            z-index: 5;
            width: 80%;
            margin-top: 18%;
            text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.6);
            @include mq("small") {
                position: relative;
                margin-top: 15%;
            }
            .regional-contact__locations {
                position: absolute;
                transform: translateY(-20px);
                opacity: 0;
                transition: all 0.5s;
                @include mq("small") {
                    position: static;
                    transform: translateY(0);
                    opacity: 1;
                }
            }
            h2 {
                font-size: 4rem;
                line-height: 1;
                margin-bottom: 1rem;
                @include mq("small") {
                    font-size: 2.6rem;
                }
            }
            h3 {
                font-size: 2rem;
                margin-top: 3rem;
                margin-bottom: 1rem;
                transition: transform 0.5s;
                @include mq("small") {
                    font-size: 1.8rem;
                    margin-top: 2.6rem;
                }
            }
            a {
                display: block;
                font-size: 1.2rem;
                text-transform: uppercase;
                letter-spacing: 1px;
                transition: transform 0.5s;
            }
        }
    }
}

// Basic Layout Page (Big bg left and text right)
.basic-layout-main {
    background-repeat: no-repeat;
    background-size: 42%;
    @include mq("medium") {
        background-size: 50%;
    }
    @include mq("small") {
        background-size: 60%;
    }
}
.basic-layout-top {
    margin-top: $v-margin * 2;
    margin-bottom: $v-margin * 2;
    @include mq("small") {
        margin-bottom: $v-margin-sp;
    }
    &__left {
        width: 55%;
        height: 100vh;
        // margin-top: 25vh;
        position: relative;
        .bg-image {
            filter: grayscale(100%);
        }
        @include mq("small") {
            width: 70%;
        }
    }
    &__right {
        width: 42%;
        position: relative;
        top: 50%;
        left: 50%;
        .line {
            margin-top: 2rem;
        }
        @include mq("medium") {
            width: 45%;
        }
        @include mq("small") {
            position: relative;
            width: 90%;
            left: 5%;
            margin-top: -20rem;
        }
    }
    &__intro {
        width: 90%;
        font-size: 2.6rem;
        line-height: 1.3;
        margin-top: 3rem;
        @include mq("medium") {
            font-size: 2.2rem;
        }
        @include mq("small") {
            font-size: 2.0rem;
        }
    }
    &__text {
        width: 90%;
        margin-top: 3rem;
        line-height: 1.6;
        text-align: justify;
        @include mq("small") {
            width: 100%;
            text-align: left;
		}
		ul {
			margin-left: 2rem;
		}
		li {
			list-style: disc;
		}
	}
}
