.background {
    background-size: cover;
    background-position: center center;
    min-height: 44.3vw;

    &__title {

    }
    &__subtitle.subtitle {
        font-size: 21px !important;
        line-height: 31px !important;
    }
    &__button {
        margin-top: 60px;
    }
}