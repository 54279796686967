.page-template-page-template-new, .blog, .single-post {

	.main { 
		position: relative; 
		overflow: hidden;
		@media screen and (max-width: 991px) {
			padding-top: 0;
		}
		padding-top: 0;
		.bg {
			// position: relative;

			&__section {
				height: 100vw;
				width: 100%;
				left: 0;
				right: 0;
				position: absolute;
				margin: auto;

				// &--1 {
				// 	background: radial-gradient(50% 50% at 50% 50%, rgba(226, 164, 74, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
				// }
			}
		}

		.bg__section {
			width: 100vw;
			height: 100vh;
			z-index: -1;
			&--1 { // first glow below fold
				background: radial-gradient(50% 50% at 50% 50%, rgba(226, 164, 74, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
				top: 80vh;
				height: 60vw;
				width: 60%;
			}
			&--4 { // footer glow
				background: radial-gradient(100% 100% at 50% 0%, rgba(6, 6, 6, 0) 24.48%, rgba(226, 164, 74, 0.2) 47.92%, rgba(99, 73, 40, 0) 100%);
				bottom: -20vh;
				width: 150%;
				left: -25%;
			}
		}

		&--Home {
			.bg__section {
				&--1 {
					top: 140vh;
				}
				&--3 {
					background: radial-gradient(50% 50% at 50% 50%, rgba(226, 164, 74, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
					top: 295vh;
					height: 60vw;
					width: 60%;
				}
				// &--2 {
				// 	background: radial-gradient(100% 100% at 50% 0%, rgba(6, 6, 6, 0) 24.48%, rgba(226, 164, 74, 0.2) 47.92%, rgba(99, 73, 40, 0) 100%);
				// 	top: 220vh;
				// 	height: 100vh;
				// 	width: 150vw;
				// 	left: -25vw;
				// }
			}
			.background__subtitle {
				max-width: 550px;
			}
		}
		&--Hospitality {
			.bg__section {
				// &--1 {
				// 	background: radial-gradient(50% 50% at 50% 50%, rgba(226, 164, 74, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
				// 	top: 100vh;
				// 	height: 60vw;
				// 	width: 60%;
				// }

				// In Hospitality page, the background overlaps to hero texts
				// &--2 {
				// 	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
        //             mix-blend-mode: multiply;
        //             height: 20vh;
				// 	top: 80vh;
				// 	z-index: 1;
				// }
			}
			.hero {
				&__title {
					max-width: 520px;
				}
				&__subtitle {
					max-width: 500px;
				}
			}
			.info {
				&__subtitle {
					max-width: 580px;
				}
			}
		}
		&--Info {
			.info {
				&__subtitle {
					max-width: 550px;
				}
			}
		}
		&--Events {
			.bg__section {
				// &--1 {
				// 	background: radial-gradient(50% 50% at 50% 50%, rgba(226, 164, 74, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
				// 	top: 195vh;
				// 	height: 60vw;
				// 	width: 60%;
				// }
				// &--2 {
				// 	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
                //     mix-blend-mode: multiply;
                //     height: 20vh;
				// 	top: 80vh;
				// 	z-index: 1;
				// }
			}
			.hero {
				&__title {
					max-width: 500px;
				}
				&__subtitle {
					max-width: 500px;
				}
			}
			.info {
				&__subtitle {
					max-width: 400px;
				}
				&__title {
					max-width: 600px;
				}
			}
		}
		&--On-Site {
			.bg__section {
				// &--1 {
				// 	background: radial-gradient(50% 50% at 50% 50%, rgba(226, 164, 74, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
				// 	top: 90vh;
				// 	height: 60vw;
				// 	width: 60%;
				// }
				&--2 {
					background: radial-gradient(50% 50% at 50% 50%, rgba(226, 164, 74, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
					top: 290vh;
					height: 60vw;
					width: 60%;
				}
			}
			.hero {
				&__title {
					max-width: 500px;
				}
				&__subtitle {
					max-width: 500px;
				}
			}
			.info {
				&__title {
					max-width: 700px;
				}
				&__subtitle {
					max-width: 560px;
				}
			}
		}
		&--Artists {
			.bg__section {
				// &--1 {
				// 	background: radial-gradient(50% 50% at 50% 50%, rgba(226, 164, 74, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
				// 	top: 90vh;
				// 	height: 60vw;
				// 	width: 60%;
				// }
				&--2 {
                    background: radial-gradient(100% 100% at 50% 0%, #100859 0%, #3B328C 24.48%, #9C2F87 47.92%, rgba(99, 73, 40, 0) 100%);
                    transform: rotate(-180deg);
                }
                &--1 {
                    background: #E2A44A;
                }
                &--3 {
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
                    mix-blend-mode: multiply;
                    height: 20vh;
					top: 80vh;
                }
			}
		}
		&--About {
			.bg__section {
				// &--1 {
				// 	background: radial-gradient(100% 100% at 50% 0%, rgba(6, 6, 6, 0) 24.48%, rgba(226, 164, 74, 0.1) 47.92%, rgba(99, 73, 40, 0) 100%);
				// 	top: 90vh;
				// 	height: 100vh;
				// 	width: 150vw;
				// 	left: -25vw;
				// }
			}
			.hero {
				@media screen and (max-width: 991px) {
					height: auto;
				}
				&__title {
					max-width: 720px;
				}
				&__subtitle {
					max-width: 730px;
				}
			}
		}
		&--News {
			.bg__section {
				&--2 {
                    // background: radial-gradient(100% 100% at 50% 0%, #100859 0%, #3B328C 24.48%, #9C2F87 47.92%, rgba(99, 73, 40, 0) 100%);
                    // transform: rotate(-180deg);
                }
                // &--1 {
				// 	background: #E2A44A;
				// 	margin-left: 50px;
				// 	margin-right: 50px;
				// 	top: 20vh;
				// 	height: 60vh;
				// 	width: auto;
				// 	@media screen and (max-width: 991px) {
				// 		margin: 0 20px;
				// 	}
                // }
                // &--4 {
                //     background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
                //     mix-blend-mode: multiply;
                //     height: 55vh;
				// 	top: 45vh;
				// }
				&--vid {
					height: 75vh;
					overflow: hidden;
					@media screen and (max-width: 991px) {
						display: none;
					}
					video {
						position: absolute;
						bottom: 0;
						width: 100%;
					}
					&--mobile {
						display: none;
						@media screen and (max-width: 991px) {
							display: block;
						}
					}
				}
			}
		}
		&--Contact {

			.contact__container {
				width: 100%;
				min-height: 75vh;
				background: radial-gradient(33.33% 50% at 50% 50%, rgba(226, 164, 74, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.contact__wrapper {
				width: 100%;
			}

			.contact__info {
				width: 33% !important;
				margin: 0 auto;
				margin-bottom: 64px;

				@media screen and (max-width: 991px) {
					width: 100% !important;
				}
			}
			
			.contact__name {
				font-weight: 300;
				font-size: 24px;
				line-height: 28px;
				text-align: center;
				margin-bottom: 2rem;

			@media screen and (max-width: 480px) {	
				font-size: 24px !important;
				}
			}

			.contact__phone_email {
				font-weight: 100;
				font-size: 28px;
				line-height: 36px;
				text-align: center;

			@media screen and (max-width: 480px) {
					font-size: 36px !important;
				}
			}
		}
	}

	.header {

		@media screen and (max-width: 991px) {
			display: none;
		}

		top: 0;
		height: auto;
		position: fixed;
		padding-top: 20px;
		padding-bottom: 20px;
		background: black;
		.current-menu-item a{
			position: relative;
			&:after {
				content: '';
				border-bottom:1px solid white;
				display: block;
				position: absolute;
				right: 3px;
				left: 0;
			}
		}

		.navigation .menu-item {
			@media screen and (max-width: 1300px) {
				margin-right: 10px;
			}
		}
		&__center {
			position: static;
			transform: none;
			flex: 0 1 auto !important;

			a img {
				width: 240px;
				@media screen and (max-width: 1300px) {
					width: 185px;
				}
			}

			// #logo-animation {

			// 	a {
			// 		margin-left: auto;
			// 		margin-right: auto;
			// 		width: auto;

			// 		svg {
			// 			width: auto !important;
			// 			height: 50% !important;
			// 		}
			// 	}
			// }
		}
		&__right {
			display: flex;
			justify-content: flex-end;
			.button {
				font-weight: bold;
				font-size: 10px;
				color: black;
				line-height: 26px;
				letter-spacing: 3px;
				text-transform: uppercase;
				background: #E2A44A;
				height: fit-content;
				padding: 5px;
				padding-left: 10px;
				padding-right: 10px;
				width: auto;
				margin-top: -5px;
				border: none;
			}
			a {
				font-weight: bold;
			}
		}
		&__left {

		}
		&--mobile {
			display: none;
			.mobile-logo {
				width: 150px;
				margin-right: auto;
				margin-top: 20px;
			}
			.hamburger {
				top: 2rem;
			}
			@media screen and (max-width: 991px) {
				display: block;
			}
			.main-nav .navigation li {
				font-family: 'Lato';
			}
		}
		&.Artists {
			.header__right .button {
				background: black;
				color: white;
				border: 1px solid white;
			}
		}
	}

	.header-container {
		> * {
			flex: 1 0 30%;
		}
		.navigation {
			text-transform: uppercase;
			font-size: 10px;
			line-height: 26px;
			letter-spacing: 3px;
		}
		align-items: center;
		&.wrapper {
			@media screen and (max-width: 1300px) {
				margin-left: 20px;
				margin-right: 20px;
			}
		}
	}

	section {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 15vh;
		padding-bottom: 15vh;
	
		&.circle {
			height: 100vw;
			background: radial-gradient(50% 50% at 50% 50%, rgba(226, 164, 74, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
			margin-top: calc(50vh - 50vw);
			margin-bottom: calc(50vh - 50vw);
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			position: relative;
			width: 100vw;
		}
		&:first-child {
			min-height: calc(100vh - 140px);
			height: auto;
			margin-top: 140px;
			@media screen and (max-width: 991px) {
				height: auto;
				margin-top: 0;
			}
		}
	}
	
	.title {
		font-weight: 300;
		font-size: 32px;
		line-height: 34px;
		letter-spacing: 6px;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 60px;
		max-width: 800px;
		@media screen and (max-width: 991px) {
			font-size: 24px;
			line-height: 26px;
			margin-left: 20px;
            margin-right: 20px;
        }
	}
	
	.subtitle {
		font-weight: 300;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		letter-spacing: 1px;
		@media screen and (max-width: 991px) {
			font-size: 18px;
			line-height: 22px;
			margin-left: 20px;
            margin-right: 20px;
        }
	}
	
	.button { 
		font-weight: bold;
		font-size: 10px;
		line-height: 26px;
		letter-spacing: 3px;
		text-transform: uppercase;
		width: 240px;
		border-radius: 4px;
		border: 1px solid white;
		a {
			color: white;
		}
	}
	
	.wrapper {
		padding-left: 50px;
		padding-right: 50px;

		@media screen and (max-width: 991px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	
	.inner-wrapper {
		max-width: 800px;
		margin: auto;

		@media screen and (max-width: 991px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
    
    #mc_embed_signup_scroll {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		@media screen and (max-width: 991px) {
			flex-direction: column;
			align-items: center;
		}
		#mc-embedded-subscribe {
			color: white;
			border-bottom-color: white;
			padding: 0;
			@media screen and (max-width: 991px) {
				width: 100%;
			}
		}
		.mc-field-group {
			text-transform: uppercase;
			flex: 1 0 auto;
			margin-right: 20px;
			@media screen and (max-width: 991px) {
				margin-right: 0;
				width: 100%;
			}
			input {
				border-bottom-color: white;
				text-transform: uppercase;
				padding: 1rem 0;
				&::placeholder {
					color: #626262;
					font-size: 10px;
					letter-spacing: 3px;
				}
			}

		}
		.clear {
			@media screen and (max-width: 991px) {
				width: 100%;
				margin-top: 20px;
			}

		}
	}

	.artists > .artists__slider > a > .artists__slider__bg,
	.artists > .artists__slider > a > .artists__slider__name {
		display: none;
	}
	
    .footer {
		font-size: 12px;
		letter-spacing: 1px;
		margin-top: calc(50px + 10vh);
		margin-bottom: 50px;
		.copyright {
			margin-top: 0;
		}
		.flex-box {
			flex-wrap: nowrap;
			align-items: center;
	
			@media screen and (max-width: 991px) {
				flex-direction: column;
			}
		}
		&__top {
			margin-bottom: 60px;
			&__left {
				flex: 0 0 30%;

				@media screen and (max-width: 991px) {
					text-align: center;
					
				}

				.title {
					margin-left: 0;
					margin-right: 0;
					text-transform: none;
					text-align: left;
					margin-bottom: 0;
					letter-spacing: 1px;

					@media screen and (max-width: 1630px) {
						// letter-spacing: 3px;
					}
					@media screen and (max-width: 1400px) {
						font-size: 24px;
					}
					@media screen and (max-width: 1140px) {
						// letter-spacing: 1px;
					}
					@media screen and (max-width: 991px) {
						margin-bottom: 20px;
					}
				}
			}
			&__right {
				flex: 0 0 calc(2/3 * 100%);
				&__text {
					margin-bottom: 10px;
					font-size: 14px;
				}
			}
		}
		&__bot {
			> * {
				flex: 1 0 30%;
				@media screen and (max-width: 991px) {
					margin-top: 30px;
				}
			}
			&__right {
				text-align: right;
				@media screen and (max-width: 991px) {
					display: flex;
					text-align: center;
					width: 100%;
					justify-content: center;
					flex-wrap: wrap;
				}
				& > div {
					text-align: left;
					margin-bottom: 20px;
				}
				a {
					margin-left: 20px;
					@media screen and (max-width: 991px) {
						margin-right: 5px;
						margin-left: 5px;
					}
				}
			}
			&__center {
				text-align: center;
				display: flex;
				justify-content: flex-start;
				@media screen and (max-width: 991px) {
					width: 100%;
					flex-wrap: wrap;
					justify-content: center;
				}

				a {
					@media screen and (max-width: 991px) {
						flex: 0 0 25%;
						margin-left: 5px;
						margin-right: 5px;
					}
					margin-right: 20px;
				}
			}
			.fab {
				font-size: 16px;
			}
		}
	}
}

.single-post {
	h1, h2, h3, h4, h5, h6 {
		font-family: 'Lato';
		font-weight: 300;
	}
	h3 {
		text-transform: uppercase;
		letter-spacing: 10px;
		font-weight: 400;
		margin-bottom: 30px;
	}
	h1 {
		font-size: 42px;
	}
	a {
		font-family: 'Lato';
	}
	&__content {
		display: inline-block;
	}
	.wide-link {
		background: none;
	}
	.wide-link--contact {
		overflow: hidden;
		a {
			font-weight: 300;
		}
		video {
			position: absolute;
			left: 0;
			width: 100%;
			top: 0;
			transform: rotate(180deg);
			z-index: -1;
		}
	}
}
