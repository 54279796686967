.header {
    width: 100%;
    height: 20px;
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 100;
    transform: translate3d(0, 0, 0); // For Chrome bug
    transition:top 1s, opacity 1s;
    @include mq("small") {
        top: 0;
        padding-top: 3rem;
    }
    &__center {
        position: absolute;
        left: 52%;
        transform: translateX(-50%);
        @include mq("small") {
            position: static;
            transform: translateX(0);
        }
    }
    &__left {
        align-items: baseline;
        @include mq("small") {
            display: none;
        }
        a {
            font-weight: bold;
            i {
                margin-right: 0.5rem;
            }
        }
    }
    .loading & {
        opacity: 0;
        top: -200px;
    }
}

// Logo
.front {
    #logo {
        display: block;
    }
    #logo-animation {
        display: block;
    }
}
#logo {
    display: none;
}
#logo-animation {
    display: block;
}

.logo {
    opacity: 1;
    visibility: visible;
}

.logo a {
    width: 320px;
    height: 140px;
    transition:all 0.5s;
    margin-top: -1.4rem;
    .front &{
        width: 500px;
        height: 195px;
        @include mq("medium") {
            width: 380px;
            height: 145px;
        }
        @include mq("small") {
            margin-top: -3.6rem;
            width: 140px;
            height: 100px;
        }
    }
    @include mq("small") {
        margin-top: -2.6rem;
        width: 140px;
        height: 100px;
    }
}


// Main Navigation
.main-nav {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $bg-color;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    @include mq("small") {
        top: 0;
    }

    .flex-box {
        @include mq("small") {
            flex-direction: column-reverse;
        }
    }

    .navigation {
        li {
            font-family: $serif;
            overflow: hidden;
            @include mq("small") {
                margin-bottom: 0.5em;
                text-align: center;
            }
            a {
                font-size: 5rem;
                line-height: 1.6;
                transition: color 0.3s;
                &:hover {
                    color: $color-grey-600;
                }
                @include mq("small") {
                    font-size: 3rem;
                }
            }
            a.active {
                color: $color-grey-600;
                pointer-events: none;
                cursor: default;
            }
        }
    }

    &__logo {
        width: 260px;
        margin-bottom: 3rem;
        @include mq("small") {
            display: none;
        }
    }

    &__sns {
        @include mq("small") {
            display: none;
        }
        a {
            margin-bottom: 1rem;
            text-transform: uppercase;
            font-size: 1rem;
            letter-spacing: 1px;
            font-weight: bold;
            transition: color 0.3s;
            &:hover {
                color: $color-grey-600;
            }
        }
    }
}


.hamburger {
    width: 30px;
    height: 50px;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.5s, opacity 1s, top 1s;
    transform-origin: right center;
    position: absolute;
    z-index: 200;
    right: 0;
    top: 4rem; 
    .loading & {
        opacity: 0;
        top: -200px;
    }
    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $body-color;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2) {
            top: 8px;
        }
        &:nth-child(3) {
            top: 16px;
        }
    }
}

// Navigation Open
.is-open {
    .main-nav {
        opacity: 1;
        visibility: visible;
    }
    .hamburger span {
        &:nth-child(1) {
            top: 10px;
            transform: rotate(135deg);
        }
        &:nth-child(2) {
            opacity: 0;
            left: 60px;
        }
        &:nth-child(3) {
            top: 10px;
            transform: rotate(-135deg);
        }
    }
}

.header {
    .header-container {
        .navigation {
            display: flex;
            li {
                margin-right: 20px;
            }
        }
    }
}

