.grid {
    margin: $v-margin 0;
    @include mq("small") {
        margin: $v-margin-sp 0;
    }
    &__text {
        width: 49%;
        margin-top: 1rem;
        margin-bottom: $v-margin;
        font-size: 1.6rem;
        line-height: 1.6;
        text-align: justify;
        @include mq("small") {
            width: 100%;
            margin-bottom: $v-margin-sp;
            text-align: left;
        }
    }
}

.grid__item {
    width: 23%;
    margin-bottom: $v-margin / 2;
    display: block;
    @include mq("small") {
        width: 48%;
        margin-bottom: $v-margin-sp / 2;
    }
    &:hover {
        img {
            filter: grayscale(0);
        }
    }
    img {
        filter: grayscale(1);
        transition: filter 0.5s;
    }
    h3 {
        letter-spacing: 1px;
        margin: 1.4rem 0 0.8rem;
    }
    p {
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 1px;
    }
    &__link{
        position: relative;
        display: inline-block;
    }
}

.two-column {
    margin-bottom: $v-margin;
    position: relative;
    @include mq("small") {
        margin-bottom: $v-margin-sp;
    }
    &__left {
        position: relative;
        width: 50%;
        z-index: 1;
        @include mq("small") {
            position: relative;
            z-index: 1;
            width: 100%;
            top: -1.2rem;
        }
    }
    &__right {
        width: 50%;
        height: 400px;
        overflow: hidden;
        position: relative;
        @include mq("medium") {
            position: absolute;
            width: 70%;
            height: 280px;
            z-index: 0;
            right: 0;
        }
        @include mq("small") {
            position: absolute;
            top: 0;
            z-index: 0;
            width: 100%;
            height: 200px;
        }
        .bg-image {
            transition: transform 0.6s;
            filter: grayscale(1);
        }
    }
    // Right Column Style
    &__title {
        position: relative;
        font-size: 7rem;
        margin-top: $v-margin / 2;
        width: 80%;
        @include mq("small") {
            font-size: 5rem;
            width: 100%;
            text-align: center;
            margin-top: 3.6em;
        }
    }
    .line {
        width: 100%;
        height: 1px;
        background-color: $line-color;
        margin: 3rem 0;
        @include mq("medium") {
            position: relative;
            z-index: 0;
        }
        @include mq("small") {
            margin: 2rem 0 ;
        }
    }
    &__text {
        width: 90%;
        font-size: 1.6rem;
        margin-bottom: 2rem;
        @include mq("small") {
            width: 100%;
            font-size: 1.4rem;
        }
    }
    &__link {
        display: inline;
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 2px;
        padding: 0 0 5px;
        @include mq("medium") {
            text-align: right;
        }
        i {
            font-size: 1.6rem;
            color: $key-color;
            vertical-align: middle;
        }
    }
    &.hover {
        .two-column__right {
            .bg-image {
                filter: grayscale(0);
                transform: scale(1.05);
            }
        }
    }
}

.two-column-reverse {
    .two-column__left {
        order: 2;
        @include mq("medium") {
            order: initial;
        }
        .two-column__title {
            margin-left: 20%;
            @include mq("medium") {
                text-align: left;
                margin-left:0;
            }
            @include mq("small") {
                text-align: center;
            }
        }
        .two-column__text, .two-column__link {
            margin-left: 20%;
            @include mq("medium") {
                margin-left:0;
            }
        }   
    }
}