.contact { 

    #contact {
        width: 100%;
        @media screen and (max-width: 991px) {
            margin-left: 10px;
            margin-right: 10px;
        }

        button {
            font-weight: bold;
            font-size: 10px;
            color: black;
            line-height: 26px;
            letter-spacing: 3px;
            text-transform: uppercase;
            background: #E2A44A;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            padding: 5px;
            width: 175px;
            display: block;
            margin: auto;
            border-radius: 4px;
            &#prevBtn {
                background: none;
                color: #838383;
                margin-top: 20px;
                text-transform: none;
                max-width: none;
                width: auto;
                display: flex;
                line-height: 12px;
                letter-spacing: 2px;
                img {
                    margin-right: 7px;
                    display: inline-block;
                }
            }
        }
        #messages {
            margin-bottom: 40px;
        }
        .custom-select {
            position: relative;
            // font-family: Arial;
            text-align: left;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 3px;
            text-transform: uppercase;
            // border-bottom-color: #313131;
            color: white;
            padding: 0;
            @media screen and (max-width: 991px) {
                min-width: 95%;
                margin-bottom: 30px;
            }
          }
          
          .custom-select select {
            display: none; /*hide original SELECT element: */
          }
          
          .select-selected {
            background-color: black;
            border-bottom: 1px solid white;
            &.select-arrow-active {
                border-bottom: 1px solid #E2A44A;
            }
          }
          
          /* Style the arrow inside the select element: */
          .select-selected:after {
            position: absolute;
            content: "";
            top: 25%;
            right: 10px;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-color: #fff transparent transparent transparent;
          }
          
          /* Point the arrow upwards when the select box is open (active): */
          .select-selected.select-arrow-active:after {
            border-color: transparent transparent #E2A44A transparent;
            top: 0;
          }
          /* style the items (options), including the selected item: */
          .select-items div,.select-selected {
            color: #ffffff;
            // padding: 8px 16px;
            // border: 1px solid transparent;
            // border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
            cursor: pointer;
            background: black;
          }
          .select-items div {
              padding: 5px 0;
          }
          
          /* Style items (options): */
          .select-items {
            position: absolute;
            background-color: black;
            top: calc(100% + 1px);
            left: 0;
            right: 0;
            z-index: 99;
            height: 220px;
            overflow: scroll;
            text-align: center;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar { 
                display: none;  /* Safari and Chrome */
            }
          }
          
          /* Hide the items when the select box is closed: */
          .select-hide {
            display: none;
          }
          
          .select-items div:hover, .same-as-selected {
            background-color: rgba(0, 0, 0, 0.1);
          }
    }

    .step-container {
        text-align:center;
        margin-top:40px;
        line-height: normal;
    }



    &__row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 40px;
        @media screen and (max-width: 991px) {
            flex-direction: column;
            margin-bottom: 0;
        }

        input[type] {
            border-bottom: 1px solid white;
            @media screen and (max-width: 991px) {
                min-width: 95%;
                margin-bottom: 30px;
            }
            &::placeholder {
                // color: $color-grey-600;
                color: white;
            }
        }
        input[type="text"], input[type="email"], input[type="number"], input[type="date"], input[type="time"], .custom-select.event, .custom-select.hear, .custom-select.location {
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 3px;
            text-transform: uppercase;
            flex: 0 1 49%;
            // border-bottom-color: #313131;
            color: white;
            padding: 0;

            &::placeholder {
                // color: $color-grey-600;
                color: white;
            }
            &:focus, &:active {
                border-bottom-color: rgb(225, 163, 82);
            }
        }
        input[name="date"] {
            flex: 0 1 49%;
            background: black;
            border-top: none;
            border-left: none;
            border-right: none;
            text-transform: uppercase;
            &::placeholder {
                color: $color-grey-600;
                color: white;
            }
        }

        input[name="start-time"], input[name="end-time"], .custom-select.start-time, .custom-select.end-time {
            flex: 0 1 24%;
            background: black;
            border-top: none;
            border-left: none;
            border-right: none;
            text-transform: uppercase;
        }
    }

    &__title.title {
        margin-bottom: 40px !important;
    }
    
    &__subtitle.subtitle {
        max-width: none;
        margin-bottom: 60px;

        &.invalid {
            color: #ffdddd;
        }
    }



    textarea {
        border: 1px solid #313131;
        border: 1px solid white;
        padding: 20px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 1px;
        color: white;
        margin-top: 40px;
        &::placeholder {
            color: white;
        }
        // margin-bottom: 35px;
    }

    input[type="submit"] {
        font-weight: bold;
		font-size: 10px;
		line-height: 26px;
		letter-spacing: 3px;
		text-transform: uppercase;
        padding: 5px 60px;
        color: #838383;
        border: 1px solid #313131;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: #FFFFFF !important;
        -webkit-box-shadow: 0 0 0 30px #000000 inset !important;
    }
      
      /* Mark input boxes that gets an error on validation: */
      input.invalid {
        background-color: #ffdddd;
      }
      
      /* Hide all steps by default: */
      .contact__tab {
        display: none;
        margin-bottom: 60px;
      }
      
      /* Make circles that indicate the steps of the form: */
      .step {
        height: 8px;
        width: 8px;
        margin: 0 2px;
        // background-color: #bbbbbb;
        border: 1px solid #bbbbbb;
        border-radius: 50%;
        display: inline-block;
        opacity: 0.5;
      }
      
      /* Mark the active step: */
      .step.active {
        opacity: 1;
        background-color: #bbbbbb;
      }
      
      /* Mark the steps that are finished and valid: */
      .step.finish {
        // background-color: #4CAF50;
      }

    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        padding: 10px 20px;
        border: 1px solid white;
        flex: 0 0 calc(50% - 5px);

        &:hover {
            border: 1px solid #E2A44A;
        }
        @media screen and (max-width: 991px) {
            margin-bottom: 10px;
        }
    }

    input[type="radio"]:checked + label {
        border: 1px solid #E2A44A;
    }

    input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        height: 1px;
        background: #fff;
        outline: none;
        z-index: 1;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background: #E2A44A;
            cursor: pointer;
        }
    }

    .slider-container {
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        position: relative;

        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }

    .slider__gauge {
        display: flex;
        justify-content: space-between;
    }

    span {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 26px;
        text-align: right;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #838383;
    }

    #slider__current-budget{
        position: absolute;
        left: 0%;
        bottom: 36px;
        padding-left: 20px;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #fff;

        @media screen and (max-width: 991px) {
            bottom: 67px;
        }
    }

    .bubble {
        position: absolute;
        left: 0%;
        bottom: 20px;
    }

    #bubble {
        position: absolute;
        left: 0%;
        bottom: 20px;

        @media screen and (max-width: 991px) {
            bottom: 50px;
        }
    }

    #bubble-max-budget {
        position: absolute;
        right: 0%;
        transform: translateX(200px);
        bottom: 20px;
        display: none;

        @media screen and (max-width: 991px) {
            right: 0%;
            bottom: 50px;
            transform: translateX(0px);

            svg {
                margin-left: calc(100% - 300px);
            }
        }
    }



}