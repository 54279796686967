.blog {

    .main--News {

        .line {
            margin: 0;
            width: calc(100% + 100px);
            margin-left: -50px;
            background-color: #313131;
        }

        .contact {
            // height: auto;
        }
    }

    .featured-post {
        height: 75vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 10px;
        line-height: 26px;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-weight: bold;
        // @media screen and (max-width: 991px) {
        //     padding: 0 20px;
        // }

        &__date {

        }

        &__link {
            padding: 5px 20px;
            border: 1px solid white;
            width: 150px;
            text-align: center;
        }
        &__title {
            // font-weight: 300;
            // font-size: 32px;
            // line-height: 40px;
            // letter-spacing: 6px;
            // text-transform: uppercase;
            margin: 30px 0;
            max-width: 850px;
            text-align: left;
            @media screen and (max-width: 991px) {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }

    .post {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        line-height: 26px;
        letter-spacing: 3px;
        text-transform: uppercase;
        font-weight: bold;
        

        &:hover {
            .post__content {
                &__title {
                    border-bottom: 1px solid #E2A44A;
                }
                &__link {
                    border-bottom: 1px solid #E2A44A;
                }
            }
            .post__img {
                opacity: 1;
            }
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 20px 0;
        }


        &__date {
            flex: 0 0 15%;
        }

        // &__link {
        //     flex: 0 0 15%;
        //     text-align: right;
        // }

        &__content {
            flex: 0 1 70%;
            max-width: 70%;
            padding-right: 50px;
            @media screen and (max-width: 991px) {
                max-width: 100%;
                padding-right: 0;
            }

            &__title {
                font-weight: 300;
                font-size: 28px;
                line-height: 36px;
                letter-spacing: 1px;
                // flex: 0 1 55%;
                text-transform: none;
                margin: 5px 0;
                padding: 5px 0;
                display: inline-block;
                height: 48px;
                @media screen and (max-width: 991px) {
                    font-size: 21px;
                    line-height: 29px;
                    text-align: left;
                    height: auto;
                }
            }
            &__blurb {
                margin: 5px 0;
                padding: 5px 0;
                text-transform: none;
                letter-spacing: 1px;
                font-size: 14px;
                line-height: 22px;
                font-weight: normal;
                height: auto;
                p {
                    overflow: Hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    @media screen and (max-width: 991px) {
                        display: -webkit-box;
                        white-space: normal;
                        /* autoprefixer: off */
                        -webkit-box-orient: vertical;
                        /* autoprefixer: on */
                        -webkit-line-clamp: 3;
                    }
                }
            }
            &__link {
                margin: 5px 0;
                padding: 5px 0;
                display: inline-block;
                line-height: normal;
            }
        }


        &__img {
            flex: 0 0 15%;
            background-size: cover;
            background-position: center center;
            transition: transform 0.5s ease;
            // min-height: 250px;
            width: 100%;
            height: 250px;
            opacity: 0;
            transition: opacity 1s ease;
            @media screen and (max-width: 991px) {
                display: none;
            }
        }
    }

    // .header {
    //     &__right {
    //         .button {
    //             color: white;
    //             background: black;
    //         }
    //     }
    // }



}

