.single-project-main {
    padding-top: 0;
    &--single{
        .s-project-top__title{
            @include mq("medium") {
                font-size: 5rem;
            }
            @include mq("small") {
                width: 100%;
                font-size: 4rem;
            }
        }
    }
}
.s-project-top {
    margin-bottom: $v-margin-sp;
    &__left {
        width: 55%;
        height: 70vh;
        margin-top: 30vh;
        position: relative;
        cursor: pointer;
        @include mq("small") {
            width: 90%;
            height: 35vh;
            margin: 12rem auto 2em;
        }
        .s-project-top__bgblack {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: 2;
        }

        .bg-image {
            filter: grayscale(100%);
        }
        .play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            i {
                font-size: 6rem;
                @include mq("small") {
                    font-size: 4rem;
                }
            }
        }
    }
    &__right {
        width: 35%;
        margin-right: 5%;
        margin-top: 38vh;
        position: relative;
        z-index: 5;
        @include mq("small") {
            width: 90%;
            margin: 0 auto 0;
        }
    }

    &__title {
        font-size: 7rem;
        margin-bottom: 2rem;
        margin-left: -23%;
        // line-height: 1.2;
        @include mq("small") {
            margin-left: 0;
            font-size: 3.2rem;
        }
    }
    .line {
        width: 100px;
        margin-left: -23%;
        margin-bottom: 3rem;
        @include mq("small") {
            margin-left: 0;
        }
    }
    &__text {
        width: 90%;
        margin-top: 6rem;
        line-height: 2.2;
        text-align: justify;
        @include mq("small") {
            width: 100%;
            margin-top: 3rem;
            text-align: left;
        }
    }
}

.s-project-top__text {

    a {
        transition: color 0.6s;
        &:hover {
            color: $color-grey-600;
        }
    }
}

.s-project-gallery {
    width: 85%;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    margin-bottom: $v-margin;

    .s-project__image {
        margin: 0;
        margin-bottom: 4rem;
    }
}