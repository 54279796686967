.services-main {
    background-repeat: no-repeat;
    background-size: 42%;
    @include mq("medium") {
        background-size: 50%;
    }
    @include mq("small") {
        background-size: 60%;
    }
}

.service-detail {
    .services-top {
        margin-bottom: $v-margin * 2;
        @include mq("medium") {
            margin-bottom: $v-margin;
        }
        @include mq("small") {
            margin-bottom: $v-margin-sp * 2;
        }
    }
    .wide-link {
        margin-top: $v-margin * 2;
        @include mq("small") {
            margin-top: $v-margin-sp * 2;
        }
    }
}

.services-top {
    margin-top: $v-margin;
    margin-bottom: $v-margin * 3;
    @include mq("medium") {
        margin-top: $v-margin-sp;
        margin-bottom: $v-margin * 2;
    }
    @include mq("small") {
        margin-bottom: $v-margin-sp * 2;
    }
    &__intro {
        // text-align: center;
        // width: 65%;
        width: 85%;
        margin: 0 auto;
        line-height: 1.1;
        font-size: 7rem;
        @include mq("small") {
            font-size: 3rem;
            width: 90%;
        }
    }

    // Right Column Style
    .flex-box {
        justify-content: flex-end;
    }
    &__right {
        width: 50%;
        margin-top: $v-margin;
        @include mq("medium") {
            width: 60%;
        }
        @include mq("small") {
            margin-top: $v-margin-sp;
            width: 100%;
        }
    }
    &__title {
        font-size: 2.8rem;
        text-transform: uppercase;
        letter-spacing: 5px;
        border-bottom: 1px solid $line-color;
        padding-bottom: 2rem;
        margin-bottom: 3rem;
        font-family: $sans-serif;
        @include mq("small") {
            font-size: 2.2rem;
            padding-bottom: 1.6rem;
        }
    }
    &__text {
        font-size: 1.8rem;
        width: 85%;
        text-align: justify;
        @include mq("medium") {
            width: 100%;
        }
        @include mq("small") {
            font-size: 1.4rem;
            width: 100%;
            text-align: left;
        }
    }

    &__lists {
        margin-top: $v-margin / 2;
        h3 {
            font-size: 1.6rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 2rem;
            font-family: $sans-serif;
        }
        li {
            // text-transform: uppercase;
            margin-bottom: 0.6rem;
            margin-left: 2rem;
            list-style-type: disc;
        }
    }
}

.clients {
    margin-bottom: $v-margin / 2;
    .client-box {
        width: 70%;
        margin: 0 auto;
	    justify-content: flex-start;
        @include mq("small") {
            width: 80%;
        }
    }
    .client {
        display: inline-block;
        width: 23.3%;
        margin: 3em 5%;
        @include mq("medium") {
            width: 25%;
            margin: 1em 4%;
        }
        @include mq("small") {
            width: 35%;
            margin: 1em 7%;
        }
        &:hover {
            img {
                opacity: 1;
            }
        }
        img {
            opacity: 0.8;
            transition: opacity 0.6s;
        }
    }
}

.service-video {
    position: relative;
    padding-bottom: 53.5%;
    padding-top: 40px;
    height: 0;
    margin-bottom: $v-margin;
    // z-index: -1;
    overflow: hidden;
    transform: rotate(0.001deg); // For Chrome bug
    z-index: -1;
    @include mq("small") {
        margin-bottom: $v-margin-sp;
    }
    &:before {
        background: $bg-color;
        top: -20%;
        content: '';
        display: block;
        backface-visibility: hidden; 
        height: 20%;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewY(-6deg);
        transform-origin: 100%;
        z-index: 1;
        pointer-events: none;
        @include mq("small") {
            top: -14%;
        }
    }
    &:after {
        pointer-events: none;
        background: $bg-color;
        bottom: 0;
        content: '';
        display: block;
        height: 20%;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewY(-6deg);
        transform-origin: 100%;
        z-index: 1;
        @include mq("small") {
            bottom: 6px;
        }
    }
    
    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    } 
}