@mixin mq($break) {
  @if $break == "large" {
    @media (max-width: 82.5em) {// 1320px / 16px
      @content;
    }
  }
  @else if $break == "medium" {
    @media (max-width: 62.5em) {// 1000px / 16px
      @content;
    }
  }
  @else if $break == "small" {
    @media (max-width: 43.75em) {// 700px / 16px
      @content;
    }
  }
  @else if $break == "x-small" {
    @media (max-width: 25em) {// 400px / 16px
      @content;
    }
  }
   @else {
    @error "Whoops! No value could be retrieved for `#{$break}`. "
  }
}

@mixin mq_h($break) {
  @if $break == "long" {
    @media (max-height: 62.500em) {
      @content;
    }
  }
  @else if $break == "short" {
    @media (max-height: 31.250em) {// 500px / 16px
      @content;
    }
  }
   @else {
    @error "Whoops! No value could be retrieved for `#{$break}`. "
  }
}

// @mixin max-screen($break-point) {
//   @media screen and (max-width: $break-point) {
//     @content;
//   }
// }

// @mixin min-screen($break-point) {
//   @media screen and (min-width: $break-point) {
//     @content;
//   }
// }
