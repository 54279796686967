.hero {

    background-size: cover;
    background-position: center center;
    height: 100vh;

    &__title {
        font-weight: 300;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 1px;
        margin-bottom: 24px;
        @media screen and (max-width: 991px) {
			font-size: 24px;
            line-height: 26px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    &__subtitle {
        margin-bottom: 35px;
        @media screen and (max-width: 991px) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    &--home {
        .hero__subtitle {
            font-size: 12px;
            line-height: 26px;
            letter-spacing: 3px;
            text-transform: uppercase;
        }
        .hero__title {
            max-width: 750px;
        }
    }
    .siegel-video {
        &:hover {
            cursor: url('../img/video-cursor.png'), auto;
        }
    }
}